import localeParser from 'accept-language-parser';
import { IncomingMessage } from 'http';
import { NextApiRequestCookies } from 'next/dist/server/api-utils';
import { NextRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';
import { TenantData } from '../backend/queries/tenants'

export function shouldLanguageRedirect(tenant: TenantData, request: IncomingMessage & { cookies: NextApiRequestCookies }, locale: string | undefined, query: ParsedUrlQuery): LocaleHandlerResponse {
    if (tenant.languagesHandling.defaultLanguage && tenant.languagesHandling.defaultLanguage != locale) {
        return {
            shouldRedirect: true,
            urlPath: `/${tenant.languagesHandling.defaultLanguage}`
        }
    }

    return {
        shouldRedirect: false
    }
}

export type LocaleHandlerResponse = {
    shouldRedirect: boolean;
    urlPath?: string;
}

export const removeQueryParamsFromRouter = (router: NextRouter, removeList: Array<string> = []) => {
    if (typeof window !== 'undefined') {
        const query = router.query;
        if (Object.keys(query).length == 0) return;

        if (removeList.length > 0 && Object.keys(query).some(q => removeList.some(r => r == q))) {
            removeList.forEach((param) => delete router.query[param]);

            router.replace(
                {
                    pathname: router.pathname,
                    query: query
                },
                undefined,
                /**
                 * Do not refresh the page
                 */
                { shallow: true }
            );
        }
    }
};