import Link from 'next/link';
import { useContext } from 'react'
import { Article } from '../backend/models';
import { AppContext } from '../frontend/AppContext';
import { KbContext } from '../frontend/KbContext'
import { extractPerex } from '../utils/textTools';
import { makeUrlForArticle } from '../utils/urlizer';

export function EmptyHomeFallback() {
    const appContext = useContext(AppContext);
    const kbContext = useContext(KbContext);

    if (!kbContext || !appContext) return null;

    if (kbContext.allArticles.length > 0) {
        return <div className='flex flex-col space-y-4'>
            {kbContext.allArticles.slice(0, 5).map(i => <ArticleBox key={i.publishedContent.id.toString()} article={i} locale={appContext.tenant.currentLocale} />)}
        </div>
    } else {
        return <div><i>No article has been published yet.</i></div>
    }
}

function ArticleBox(props: { article: Article, locale: string }) {
    const appContext = useContext(AppContext);

    if (!props.article || !props.article.publishedContent) {
        return null;
    }

    return <Link href={makeUrlForArticle(props.locale, props.article, {
        isEmbedMode: appContext?.tenant.isEmbedMode,
        isNoNavMode: appContext?.tenant.isNoNavMode
    })}>
        <a className='transition-all bg-white shadow-sm p-6 rounded-xl cursor-pointer hover:shadow-md'>
            <h2 className='font-bold text-lg'>{props.article.publishedContent.title}</h2>
            {!props.article.publishedContent.lead && <p>{extractPerex(props.article.publishedContent.content)}</p>}
            {props.article.publishedContent.lead && <p>{props.article.publishedContent.lead}</p>}
        </a>
    </Link>
}