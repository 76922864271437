import { CSSProperties, useContext } from 'react'
import { AppContext } from '../../frontend/AppContext';
import { getFileUrl } from '../../utils/imaging';

export function MainShapesHeader() {
    const context = useContext(AppContext);

    if (!context) return null;

    const heroImage = context.tenant.images.hero;
    let heroImageStyles: CSSProperties = {};

    if (heroImage && heroImage.fileId) {
        heroImageStyles.backgroundImage = `url(${getFileUrl(heroImage.fileId)})`;
        heroImageStyles.backgroundPosition = heroImage.position;
        heroImageStyles.backgroundSize = heroImage.sizing;

        heroImageStyles.backgroundColor = 'unset';
        heroImageStyles.backgroundRepeat = 'no-repeat';
    }

    return <div className='relative overflow-hidden w-full -z-10 home-hero shadow-sm'
        style={{
            gridColumn: 1,
            gridRow: 1,
            ...heroImageStyles
        }}>
    </div>
}