import { useContext } from 'react'
import { Category } from '../backend/models'
import { AppContext } from '../frontend/AppContext'
import { KbContext } from '../frontend/KbContext'
import { makeDefaultUrlForCategory } from '../utils/urlizer'
import { isFlagOn } from '../utils/flags'

export function TopCategories(props: { categories: Array<Category> }) {
    const context = useContext(KbContext);

    if (context?.allArticles.length == 0) {
        return null;
    }

    return <div className='top-categories justify-center'>
        {props.categories.sort(sortByFeaturedOrder).map(category => <TopCategoryBox key={category.id.toString()} category={category}></TopCategoryBox>)}
    </div>
}

function TopCategoryBox(props: { category: Category }) {
    const context = useContext(KbContext);
    const appContext = useContext(AppContext);

    if (!context || !appContext) {
        return null;
    }

    const getIconFullPath = (icon: string) => {
        if (icon && icon.startsWith('cdn:')) {
            return getImageCdnPath(icon.replace('cdn:', ''));
        } else {
            return `/kb-temp/${icon}.svg`;
        }
    }

    const link = makeDefaultUrlForCategory(appContext?.tenant.currentLocale, props.category, context.allArticles,
        { isEmbedMode: appContext.tenant.isEmbedMode, isNoNavMode: appContext.tenant.isNoNavMode },
        isFlagOn('kb_lcat', appContext.tenant.accountInfo));

    if (!link) return null;

    return <a href={link} className='w-full max-w-sm block transition-all shadow-sm p-8 rounded-xl cursor-pointer bg-white hover:shadow-md'>
        <div className='flex'>
            <div className='text-4xl shrink-0 flex items-center justify-center'>
                {props.category.icon && <img src={getIconFullPath(props.category.icon)} className='w-[50px]' />}
            </div>

            <div className='flex flex-col ltr:ml-5 rtl:mr-5'>
                <h2 className='font-bold'>{props.category.title}</h2>
                <p>{props.category.description}</p>
            </div>
        </div>
    </a>
}

function sortByFeaturedOrder(a: Category, b: Category) {
    if (a.featuredOrder < b.featuredOrder) return -1;
    if (a.featuredOrder > b.featuredOrder) return 1;
    return 0;
}

export function getImageCdnPath(fileId: string) {
    return `https://cdn-assets.productfruits.com/${fileId}`;
}