import { Category } from '../backend/models';

export type TreelizedCategory = Category & {
    children: Array<Category>
}

export default function treelizeCategories(categories: Array<Category>): Array<TreelizedCategory> {
    const topLevelFakeParent: TreelizedCategory = {
        children: [],
        description: '',
        title: '',
        id: BigInt(0),
        icon: null,
        parentCategoryId: null,
        order: 0,
        featuredOrder: 0,
        isFeatured: false,
        slug: "",
        slugs: []
    };

    walk(categories, topLevelFakeParent)

    return topLevelFakeParent.children as Array<TreelizedCategory>;
}

function walk(remainingCategories: Array<Category>, parent: TreelizedCategory) {
    const normalizedParentId = parent.id === BigInt(0) ? null : parent.id;

    for (const category of remainingCategories.filter(cat => cat.parentCategoryId == normalizedParentId)) {
        const treelizedCategory: TreelizedCategory = {
            children: [],
            description: category.description,
            id: category.id,
            title: category.title,
            icon: null,
            parentCategoryId: category.parentCategoryId,
            order: category.order,
            featuredOrder: category.featuredOrder,
            isFeatured: category.isFeatured,
            isCollapsedByDefault: category.isCollapsedByDefault,
            slug: category.slug,
            slugs: category.slugs
        }

        parent.children.push(treelizedCategory);

        walk(remainingCategories, treelizedCategory);
    }
}