import Link from 'next/link'
import { useContext } from 'react'
import { Article } from '../backend/models';
import { AppContext } from '../frontend/AppContext'
import { KbContext } from '../frontend/KbContext';
import { makeUrlForArticle } from '../utils/urlizer';
import { extractPerex } from '../utils/textTools';
import { EmptyHomeFallback } from './EmptyHomeFallback';

export function TopArticles(props: { articles: Array<Article> }) {
    const appContext = useContext(AppContext);

    if (!appContext) {
        return null;
    }

    if (!props.articles) {
        return null;
    }

    const featuredArticles = props.articles.sort(sortByFeaturedOrder);

    if (featuredArticles.length == 0) {
        return <EmptyHomeFallback />
    }

    return <>
        <h2 className='font-bold mb-5 text-2xl'><i className="fas fa-star"></i> {appContext?.tenant.texts.featuredArticlesText}</h2>
        <div className='flex flex-col space-y-4'>
            {featuredArticles.map(article => <TopArticleBox key={article.publishedContent.id.toString()} locale={appContext.tenant.currentLocale} article={article}></TopArticleBox>)}
        </div>
    </>
}

function TopArticleBox(props: { article: Article, locale: string }) {
    const appContext = useContext(AppContext);

    if (!props.article || !props.article.publishedContent) {
        return null;
    }

    return <Link href={makeUrlForArticle(props.locale, props.article, {
        isEmbedMode: appContext?.tenant.isEmbedMode,
        isNoNavMode: appContext?.tenant.isNoNavMode
    })}>
        <a className='transition-all bg-white shadow-sm p-6 rounded-xl cursor-pointer hover:shadow-md'>
            <h2 className='font-bold text-lg'>{props.article.publishedContent.title}</h2>
            {!props.article.publishedContent.lead && <p>{extractPerex(props.article.publishedContent.content)}</p>}
            {props.article.publishedContent.lead && <p>{props.article.publishedContent.lead}</p>}
        </a>
    </Link>
}

function sortByFeaturedOrder(a: Article, b: Article) {
    if (a.featuredOrder < b.featuredOrder) return -1;
    if (a.featuredOrder > b.featuredOrder) return 1;
    return 0;
}