import { useContext } from 'react';
import { AppContext } from '../../frontend/AppContext';
import { MainMenu } from '../MainMenu';
import { MainSearchField } from '../SearchFields';
import { MainShapesHeader } from '../ui/MainShapesHeader';

export function HomeLayout(props: { children: Array<JSX.Element> | JSX.Element, noNav?: boolean, isEmbedded?: boolean }) {
    const appContext = useContext(AppContext);

    return <>
        <div className='grid'>
            <MainShapesHeader />
            <div className="mx-auto w-full" style={{ gridColumn: 1, gridRow: 1 }}>
                {!props.isEmbedded && <div className=''>
                    <MainMenu variant='transparent'></MainMenu>
                </div>}

                <div className="flex flex-col items-center desktop-area" style={{ gridColumn: 1, gridRow: 1 }}>
                    {!props.isEmbedded && <h1 className="text-4xl font-extrabold mb-16 mt-16 text-black" style={{ color: appContext?.tenant.colors.homepageHeroTextColor }}>{appContext?.tenant.title}</h1>}
                    {props.isEmbedded && <div className='mb-16 mt-16'></div>}
                    <div className="mb-32 w-full max-w-4xl">
                        <MainSearchField />
                    </div>
                </div>
            </div>
        </div>
        <div className="container max-w-7xl mx-auto">
            {props.children}
        </div>
    </>
}